/* eslint-disable import/prefer-default-export */
import { default as ChecklistUtils } from '../checklist/checklistutils.js';
import { logEntry } from './logger.js';

let lastTouchX = 0;
let lastTouchY = 0;
const linewidth = 4;

interface signature {
  lx: number;
  ly: number;
  mx: number;
  my: number;
}

const utils = new ChecklistUtils();

export class Annotate {
  public drawer = {
    // create a drawer which tracks touch movements for drawing
    isDrawing: false,
    isDrawingFreehand: false,
    drawingFreehand(state: boolean) {
      this.isDrawingFreehand = state;
      this.isDrawing = false;
    },
    touchstart(
      context: CanvasRenderingContext2D,
      coors: { x: number; y: number },
    ) {
      context.beginPath();
      context.moveTo(coors.x, coors.y);
      this.isDrawing = true;
    },
    touchmove(
      context: CanvasRenderingContext2D,
      coors: { x: number; y: number },
    ) {
      if (this.isDrawing) {
        lastTouchX = coors.x;
        lastTouchY = coors.y;
      }
      if (this.isDrawingFreehand && this.isDrawing) {
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.lineWidth = linewidth;
        context.strokeStyle = '#de7b29';
        context.lineTo(coors.x, coors.y);
        context.stroke();
      }
    },
    touchend(
      context: CanvasRenderingContext2D,
      coors: { x: number; y: number },
    ) {
      if (this.isDrawing) {
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.lineWidth = linewidth;
        context.strokeStyle = '#de7b29';
        context.lineTo(coors.x, coors.y);
        context.stroke();
        this.isDrawing = false;
      }
    },
    touchcancel() {
      if (this.isDrawing) {
        this.isDrawing = false;
      }
    },
  };

  public draw(event: TouchEvent | MouseEvent) {
    // get the touch coordinates
    // setTimeout(() => {
    if (event instanceof TouchEvent && event.touches.length > 1) {
      return;
    }
    const canvas = event.target as HTMLCanvasElement;
    const xscale = canvas.width / canvas.scrollWidth;
    const yscale = canvas.height / canvas.scrollHeight;
    let coors = {
      x: 0,
      y: 0,
    };
    if (!event.target) {
      return;
    }
    const context = canvas.getContext('2d');
    if (context == null) {
      return;
    }

    if (event instanceof MouseEvent) {
      coors = {
        x: (event.pageX - canvas.offsetLeft) * xscale,
        y: (event.pageY - canvas.offsetTop) * yscale,
      };
    }
    if (event instanceof TouchEvent) {
      coors = {
        x:
          ((event.targetTouches.length
            ? event.targetTouches[0].pageX
            : event.changedTouches[0].pageX)
            - canvas.offsetLeft)
          * xscale,
        y:
          ((event.targetTouches.length
            ? event.targetTouches[0].pageY
            : event.changedTouches[0].pageY)
            - canvas.offsetTop)
          * yscale,
      };
    }

    switch (event.type) {
      case 'mousedown':
        window.annotate.drawer.touchstart(context, coors);
        break;
      case 'mouseup':
        coors = {
          x: lastTouchX,
          y: lastTouchY,
        };
        window.annotate.drawer.touchend(context, coors);
        break;
      case 'mousemove':
        window.annotate.drawer.touchmove(context, coors);
        break;
      case 'touchstart':
        window.annotate.drawer.touchstart(context, coors);
        break;
      case 'touchend':
        coors = {
          x: lastTouchX,
          y: lastTouchY,
        };
        window.annotate.drawer.touchend(context, coors);
        break;
      case 'touchcancel':
        coors = {
          x: lastTouchX,
          y: lastTouchY,
        };
        window.annotate.drawer.touchcancel();
        break;
      case 'touchmove':
        window.annotate.drawer.touchmove(context, coors);
        break;
    }
    // }, 100);
    event.preventDefault();
  } // create a function to pass touch events and coordinates to drawer

  public drawinstallpict(img: HTMLImageElement) {
    img.style.display = '';
    const canvas = img.parentElement?.parentElement?.getElementsByTagName(
      'canvas',
    )[0] as HTMLCanvasElement;

    const canvasRatio = img.naturalHeight / img.naturalWidth;
    if (window.innerHeight * 0.9 < img.naturalHeight) {
      canvas.height = window.innerHeight * 0.9;
    } else {
      canvas.height = img.naturalHeight;
    }
    canvas.width = canvas.height / canvasRatio;
    const ctx = canvas.getContext('2d');
    const hRatio = canvas.width / img.naturalWidth;
    const vRatio = canvas.height / img.naturalHeight;
    const ratio = Math.min(hRatio, vRatio);
    const centerShift_x = (canvas.width - img.naturalWidth * ratio) / 2;
    const centerShift_y = (canvas.height - img.naturalHeight * ratio) / 2;
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(
      img,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
      centerShift_x,
      centerShift_y,
      img.naturalWidth * ratio,
      img.naturalHeight * ratio,
    );
    img.style.display = 'none';
    canvas.style.display = '';

    canvas.addEventListener('mousedown', window.annotate.draw, false);
    canvas.addEventListener('mouseup', window.annotate.draw, false);
    canvas.addEventListener('mousemove', window.annotate.draw, false);
    canvas.addEventListener('touchstart', window.annotate.draw, false);
    canvas.addEventListener('touchmove', window.annotate.draw, false);
    canvas.addEventListener('touchend', window.annotate.draw, false);
    canvas.addEventListener('touchcancel', window.annotate.draw, false);
  }

  public getinstallpict(img: HTMLImageElement, event?: Event) {
    const fr = event?.target as FileReader;
    window.utils.holdRendering = true;
    if (fr) {
      img.onload = function (img) {
        window.annotate.drawinstallpict(img.target as HTMLImageElement);
      };
      img.src = <string>fr.result;
    } else {
      window.annotate.drawinstallpict(img as HTMLImageElement);
    }
  }

  public async installpict(q: HTMLInputElement) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    let file: Blob = new Blob();
    const fr = new FileReader();
    const img = q.parentElement?.getElementsByTagName('img')[0];

    if (!img) {
      return;
    }

    if (q.files) {
      file = q.files[0] as Blob;
    } else {
      window.annotate.getinstallpict(img);
    }

    fr.onload = async function (fileEvent) {
      const resizedImage = await utils.resizeImage(<string>fileEvent.target?.result);
      window.annotate.getinstallpict(img, { target: { result: resizedImage } } as unknown as Event);
    };

    if (file.size > 0) {
      // read file as data url after 2 seconds, to allow camera app to close
      // setTimeout(() => {
      fr.readAsDataURL(file);
      // }, 500);
    } else {

    }
  }

  public drawSignature(path: string) {
    let paths: signature[] = [];
    try {
      paths = JSON.parse(path) as signature[];
    } catch (e) {
      logEntry(`${JSON.stringify(e) } ${ e}`);
      return '';
    }
    const canv = document.createElement('canvas');
    canv.height = 105;
    canv.width = self.innerWidth - 32;

    const context = canv.getContext('2d');
    if (!context) {
      return;
    }
    context.lineCap = 'round';
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.lineWidth = 2;
    context.strokeStyle = '#145394';
    for (let i = 0; i < paths.length; i++) {
      const stroke = paths[i];
      if (typeof stroke === 'object') {
        if (stroke.mx > canv.width) {
          canv.width = stroke.mx + 10;
        }
        if (stroke.lx > canv.width) {
          canv.width = stroke.lx + 10;
        }
      }
    }

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canv.width, canv.height);

    // Draw line for signature
    context.strokeStyle = '#cccccc';
    context.beginPath();
    context.moveTo(0, 85);
    context.lineTo(canv.width, 85);
    context.stroke();
    context.closePath();

    context.strokeStyle = '#145394';
    for (let i = 0; i < paths.length; i++) {
      const stroke = paths[i];
      if (typeof stroke === 'object') {
        if (!context) {
          return;
        }
        context.beginPath();
        context.moveTo(stroke.mx, stroke.my);
        context.lineTo(stroke.lx, stroke.ly);
        context.stroke();
        context.closePath();
      }
    }
    const img = canv.toDataURL('image/jpeg', 0.7);
    return img;
  }
}
