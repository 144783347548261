import 'material-icons/iconfont/material-icons.css';
import 'toastify-js/src/toastify.css';
// Import bootstrap styles + js code
import './scss/styles.scss';
import { Offcanvas } from 'bootstrap';
import { Backend, HTTPMethods } from './CPpro/Utils/http-common.js';
// import { GeoLocation } from './cppro/utils/geolocation';
import type {
  DB, User, Order, Checklist, Question, Product, QuestionType, Answer,
} from './CPpro/model/model.js';
import { ProductPropertyType, UserPropertyType, CustomArray } from './CPpro/model/model.js';
import ChecklistUtils from './CPpro/checklist/checklistutils.js';
import { Annotate } from './CPpro/Utils/annotate.js';
import {
  deleteAllData, deleteData, getData, setData,
} from './CPpro/Utils/storage.js';
import { logEntry } from './CPpro/Utils/logger.js';
import { getShowDebugInfo } from './CPpro/Utils/localstorage/debug.js';
import { getAndShowNotification, setNotification } from './CPpro/Utils/localstorage/notification.js';

let _utils: ChecklistUtils;
let _annotate: Annotate;

declare global {
  interface Window {
    utils: ChecklistUtils;
    annotate: Annotate;
    activeOrder: number;
    activeUser: User;
    activeUserMail: string;
    submenu: Order;
    activelanguage: 'NOR' | 'ENG';
    previousQuestionsAnswered: boolean;
    Data: DB;
    init: (cred?: { credential: string }) => Promise<void>;
    deleteData: (key: string) => Promise<void>;
    showOnlyRequired: boolean;
    showOnlyRemaining: boolean;
    syncing: boolean;
    logoutHandler: () => Promise<void>;
    offcanvas: Offcanvas | null,
  }
}

window.utils = window.utils || {};
window.annotate = window.annotate || {};
window.activeOrder = -1;
window.activeUser = window.activeUser || {};
window.activeUserMail = '';
window.activelanguage = window.activelanguage || 'NOR';
window.previousQuestionsAnswered = false;
window.Data = window.Data || [];
window.init = window.init || [];
window.showOnlyRequired = false;
window.showOnlyRemaining = false;
window.syncing = false;
window.offcanvas = null;

// var GPS: GeoLocation = new GeoLocation();

const Data: DB = {
  order: new CustomArray<Order>(),
  checklist: [],
  user: [],
  question: [],
  product: [],
  // answer: [],
  submenu: 'SubMenu',
  questiontype: [],
  quotes: [],
  users: [],
};

getAndShowNotification();

window.logoutHandler = async () => {
  await deleteAllData();
  document.cookie = 'loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  window.location.href = `${window.location.origin}/`;
};

// const display = document.getElementById('display');

let loggedin = false;

/*
let syncTimeout: NodeJS.Timeout;
const es = new EventSource(window.location.origin + '/event'); // Create EventSource

// Listen to events
es.addEventListener('Order', event => {
  const id = parseInt((event as MessageEvent).data);
  if (window.Data.order[window.activeOrder].id == id) {
    clearTimeout(syncTimeout);
    syncTimeout = setTimeout(() => {
      _utils.openOrder('', undefined, id.toString());
    }, 250);
  }
});
*/

function keydown(event: KeyboardEvent) {
  if (event.key === 'Enter' && window.document) {
    const element = document.activeElement as HTMLElement;

    // check if element is a textarea
    if (element.tagName.toLowerCase() === 'textarea') {
      // const text = element as HTMLTextAreaElement;
      // event.preventDefault(); // Prevent the default behavior of inserting a new line
      // const { selectionStart, selectionEnd, value } = text;
      // const newValue = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);
      // text.value = newValue;
      // text.setSelectionRange(selectionStart + 1, selectionStart + 1); // Set the cursor position after the new line
    } else {
      element.blur();
    }
  }
}

async function loadData() {
  console.log('loadData');
  // Skip loading these if user is montør (installer) -> does not have permission to this
  const skipQuotesAndProducts = window.activeUser?.properties.find((p) => p.type === UserPropertyType.Email)?.value === 'montør';

  try {
    const questionsResponse = await Backend('questions', HTTPMethods.GET);
    if (!questionsResponse.ok) {
      throw Error(questionsResponse.statusText);
    }
    const questions = await questionsResponse.json() as Question[][];

    if (questions && questions.length > 0) {
      [Data.question] = questions;
      for (const question of Data.question ?? []) {
        question.answer = new CustomArray<Answer>();
      }
    }
    await setData('Question', JSON.stringify(questions[0]));
    console.log('questions loaded.');
    const checklistResponse = await Backend('checklists', HTTPMethods.GET);
    if (!checklistResponse.ok) {
      throw Error(checklistResponse.statusText);
    }
    const checklists = await checklistResponse.json() as Checklist[][];

    if (checklists && checklists.length > 0) {
      [Data.checklist] = checklists;
    }
    if (Data.checklist) {
      for (let i = 0; i < Data.checklist.length; i++) {
        const checkl = Data.checklist[i];
        for (let j = 0; j < Data.question.length; j++) {
          const quest = Data.question[j];
          if (quest.parent_id === checkl.id) {
            if (!checkl.question) {
              checkl.question = [];
            }
            checkl.question.push(quest);
          }
        }
      }
    }
    await setData('Checklist', JSON.stringify(checklists[0]));
    console.log('checklists loaded.');
    if (!skipQuotesAndProducts) {
      const productsResponse = await Backend('products', HTTPMethods.GET);
      if (!productsResponse.ok) {
        throw Error(productsResponse.statusText);
      }
      const products = await productsResponse.json() as Product[][];
      if (products && products.length > 0) {
        [Data.product] = products;
      }
      if (Data.product) {
        // Remove all products with propertytype 16 = 181 (Deliverytime)
        Data.product = Data.product.filter((prod) => !prod.properties.find((prop) => prop.type === 16 && prop.value === 181));
        for (let i = 0; i < Data.product.length; i++) {
          const prod = Data.product[i];
          if (!prod.checklist) {
            prod.checklist = [];
          }
          prod.properties.find((prop) => {
            switch (prop.type) {
              case ProductPropertyType.CostExVAT:
                prop.value = typeof (prop.value) === 'string' ? prop.value.replace(',', '.') : prop.value;
                break;
              case ProductPropertyType.PriceExVAT:
                prop.value = typeof (prop.value) === 'string' ? prop.value.replace(',', '.') : prop.value;
                break;
              case ProductPropertyType.Stocklevel:
                prop.value = typeof (prop.value) === 'string' ? prop.value.replace(',', '.') : prop.value;
                break;
              case ProductPropertyType.VAT:
                prop.value = typeof (prop.value) === 'string' ? prop.value.replace(',', '.') : prop.value;
                break;
              default:
                break;
            }
            return true;
          });

          const checklistids: number[] = [];
          prod.properties.filter((prop) => {
            if (prop.type === ProductPropertyType.Checklist) {
              checklistids.push(parseInt(prop.value.toString()));
            }
          });
          for (let ii = 0; ii < Data.checklist.length; ii++) {
            const checkl = Data.checklist[ii];
            if (checkl.parent_id === prod.id || checklistids.includes(checkl.id)) {
              if (!prod.checklist) {
                prod.checklist = [];
              }
              if (!prod.checklist.find((c) => c.id === checkl.id)) {
                prod.checklist.push(checkl);
              }
            }
          }
        }
      }
      await setData('Product', JSON.stringify(products[0]));
      console.log('products loaded.');
    }

    const questionTypesResponse = await Backend('questiontypes', HTTPMethods.GET);
    if (!questionTypesResponse.ok) {
      throw Error(questionTypesResponse.statusText);
    }
    const questionTypes = await questionTypesResponse.json() as QuestionType[][];
    if (questionTypes && questionTypes.length > 0) {
      [Data.questiontype] = questionTypes;
    }
    await setData('QuestionType', JSON.stringify(questionTypes[0]));
    console.log('questiontypes loaded.');

    if (!skipQuotesAndProducts) {
      const quotesResponse = await Backend('quotes', HTTPMethods.GET);
      if (!quotesResponse.ok) {
        throw Error(quotesResponse.statusText);
      }
      const quotes = await quotesResponse.json() as Order[][];
      if (quotes) {
        [Data.quotes] = quotes;
      }
      await setData('Quotes', JSON.stringify(quotes[0]));
      console.log('Quotes loaded.');
    }

    // TODO Create an endpoint with only the emails instead of all..
    // TODO Fix Backend to use axios instead of fetch so we dont need to call response.json()..
    const usersResponse = await Backend('users', HTTPMethods.GET);
    const users = await usersResponse.json() as User[][];
    await setData('Users', JSON.stringify(users[0]));
    [Data.users] = users;
  } catch (e) {
    console.error(e);
  }

  console.log('data loaded.');
  console.log('loadData done.');
}

async function CPpro() {
  try {
    await _utils.renderall();
    await _utils.openOrder('Menu', undefined, undefined, false);
    await _utils.openOrder('Submenu%', undefined, undefined, false);
    // await Promise.all([menupromise, submenupromise]).then(() => {
    // await setData('Order', JSON.stringify(Data.order));
    await _utils.renderall();
    console.log('CPpro done.');
    // });
    const url = new URL(window.location.href);
    const id = url.searchParams.get('key');
    const orderId = url.searchParams.get('order') || url.searchParams.get('orderid');
    const orderOrderId = url.searchParams.get('id');
    if (id) {
      await _utils.openOrder('', undefined, undefined, false);
    } else if (orderId) {
      await _utils.openOrder(orderId, undefined, undefined, false);
    } else if (orderOrderId) {
      await _utils.openOrder(undefined, undefined, orderOrderId, false);
    } else if (window.activeOrder > 0 && window.activeOrder < Data.order.length) {
      // Open order if user had order open on refresh (moved from loadData to avoid x2)
      await _utils.openOrder('', undefined, window.Data.order[window.activeOrder].id.toString(), false);
    }

    await _utils.renderall();
  } catch (error) {
    logEntry(`${JSON.stringify(error)} ${error}`);
    console.log(error);
  }
}

async function init(cred?: { credential: string }) {
  try {
    window.Data = Data;
    _utils = new ChecklistUtils();
    _annotate = new Annotate();
    window.utils = _utils;
    window.annotate = _annotate;

    const bearertime = await getData('bearertime');
    const bearer = await getData('bearer');
    const day = new Date(Number(bearertime)).getDay();
    const today = new Date().getDay();

    document.addEventListener('keyup', keydown);

    // Render first if data exists
    if (
      await getData('User')
      && await getData('Order')
      && await getData('Checklist')
      && await getData('Question')
      && await getData('QuestionType')
      && await getData('Product')
      && await getData('Quotes')
      && bearertime
      && bearer
      && today === day // only render if bearer has not expired
    ) {
      window.activeUser = JSON.parse(await getData('User') || '{}');
      Data.order = JSON.parse(await getData('Order') || '[]');
      Data.checklist = JSON.parse(await getData('Checklist') || '[]');
      Data.question = JSON.parse(await getData('Question') || '[]');
      Data.questiontype = JSON.parse(
        await getData('QuestionType') || '[]',
      );
      Data.product = JSON.parse(await getData('Product') || '[]');
      Data.quotes = JSON.parse(await getData('Quotes') || '[]');
      window.activeOrder = JSON.parse(await getData('OrderID') || '-1');
      await _utils.renderall();
    }

    if (cred) {
      if (!cred.credential) {
        loggedin = false;
        return;
      }

      if (bearertime && bearer) {
        if (today !== day) {
          await deleteData('bearertime');
          await deleteData('bearer');
          setNotification({ text: 'Login expired - logg inn på nytt', type: 'warning' });
          document.cookie = 'loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
          window.location.reload();
        }
      } else {
        await setData('bearer', cred.credential);
        await setData('bearertime', Math.floor(new Date().getTime()).toString());
      }
      // document.getElementById('GoogleSignIn')!.style.display = 'none';
      if (!loggedin) {
        await Backend('useremail', HTTPMethods.GET).then((response) => {
          if (response.ok) {
            response.json().then(async (data) => {
              [[window.activeUser]] = data;
              window.activeUserMail = window.activeUser.properties.find((p) => p.type === 2)?.value.toString() || '';
              await setData('User', JSON.stringify(data[0][0]));
              console.log('init done.');
              loggedin = true;
              if (data[1] && data[1].newtoken) {
                await setData('bearer', data[1].newtoken);
                document.cookie = 'loggedin=1; expires=Sun, 1 Jan 2050 00:00:00 UTC; path=/';
                await setData('bearertime', Math.floor(new Date().getTime()).toString());
              }
              await loadData();
              const loginElement = document.getElementById('googlelogin');
              if (loginElement) {
                loginElement.style.display = 'none';
              }

              await CPpro();
            });
          }
        });
        const loginElement = document.getElementById('googlelogin');
        if (loginElement) {
          loginElement.style.display = 'display';
        }
        return;
      }
      const loginElement = document.getElementById('googlelogin');
      if (loginElement) {
        loginElement.style.display = 'none';
      }
    }

    // add eventlistener to blur when enter is pressed
    // document.addEventListener('keyup', keydown);
    // document.addEventListener('keyup', function (event) {
    //   if (event.key === '\n') {
    //     try {
    //       if (document.activeElement instanceof HTMLElement) {
    //         document.activeElement.blur();
    //       }
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
    // });

    try {
      if (
        await getData('User') != null
        && await getData('Order') != null
        && await getData('Checklist') != null
        && await getData('Question') != null
        && await getData('QuestionType') != null
        && await getData('Product') != null
        && await getData('OrderID') != null
        && await getData('Quotes') != null
        && await getData('bearertime') != null
      ) {
        window.activeUser = JSON.parse(await getData('User') || '{}');
        Data.order = JSON.parse(await getData('Order') || '[]');
        Data.checklist = JSON.parse(await getData('Checklist') || '[]');
        Data.question = JSON.parse(await getData('Question') || '[]');
        Data.questiontype = JSON.parse(
          await getData('QuestionType') || '[]',
        );
        Data.product = JSON.parse(await getData('Product') || '[]');
        Data.quotes = JSON.parse(await getData('Quotes') || '[]');
        window.activeOrder = JSON.parse(await getData('OrderID') || '-1');
        await _utils.renderall();
      } else if (window.activeUser && window.activeUser.properties && await getData('bearertime') != null) {
        await loadData();
        await CPpro();
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(e);
    }
  } catch (e) {
    logEntry(`${JSON.stringify(e)} ${e}`);
    console.log(e);
  }
}

window.init = init;
window.deleteData = deleteData;

// Initialize html elements
const offcanvasElement = document.getElementById('offcanvasResponsive');
if (offcanvasElement) {
  const offcanvas = new Offcanvas(offcanvasElement);
  window.offcanvas = offcanvas;
  const button = document.getElementById('offcanvasOpenBtn');
  if (button && button.getClientRects().length !== 0) {
    offcanvas.show();
  }
}

const debugElement = document.getElementById('debug');
if (debugElement && getShowDebugInfo()) {
  debugElement.className = 'visible text-danger';
}

try {
  const url = new URL(window.location.href);
  const id = url.searchParams.get('key');

  if (id) {
    // Clear local storage
    await deleteAllData();
    init({ credential: id });
  } else if (await getData('bearer') !== null) {
    if (await getData('User') !== null) {
      try {
        window.activeUser = JSON.parse(await getData('User') || '');

        // Delete all data is user is montør
        if (window.activeUser.properties.find((p) => p.type === UserPropertyType.Email && p.value === 'montør')) {
          await deleteAllData();
          // @ts-ignore
          window.activeUser = undefined;
        }
      } catch (error) {
        logEntry(`${JSON.stringify(error)} ${error}`);
        console.log(error);
        console.log(`init failed: ${error}`);
      }
    }
    const cred = { credential: await getData('bearer') || '' };
    if (cred) {
      await init(cred);
    }
  }
} catch (error) {
  logEntry(`${JSON.stringify(error)} ${error}`);
  console.log(error);
}

console.log(import.meta.env.VITE_API_URL);
console.log(import.meta.env.MODE);

const environmentElement = document.getElementById('environment');
if (import.meta.env.MODE !== 'production' && environmentElement) {
  environmentElement.className = 'visible';
}
